// Imports bootstrap.js.min
import 'bootstrap';
import MegaMenu from './components/megamenu';
import Carousel from './components/carousel';
import GeneralEnquiryForm from './components/general-enquiry-form';

console.log('TypeScript requires setup');

// The following are examples of how to export objects and functions. It is recommended to build all functionality
// in separate files as class objects, and then export those objects via this entry point
// https://webpack.js.org/configuration/output/#outputlibrary

//// Export a single initialised instance of an object
//import ExampleObject from './exampleFile';
//export const exampleExport: ExampleObject = new ExampleObject();

//// Export a function
//export function exportedFunction() {
//
//}

//// Export an object definition - this allows instances to be created as needed eg "var iObject = new Dapth.InstanceObject;"
//import InstanceObject from './an-instance-object';
//export { InstanceObject };


export { MegaMenu };
export { Carousel };
export { GeneralEnquiryForm };