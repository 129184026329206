export default class MegaMenu {

    constructor() {
    }

    Init() {

        const burgerMenu: HTMLDivElement = document.querySelector(".burger-menu") as HTMLDivElement;
        //const burgerMenuBars: HTMLDivElement = document.querySelector(".burger-menu a") as HTMLDivElement;
        const nav: HTMLDivElement = document.querySelector("nav") as HTMLDivElement;
        const navMenu: HTMLDivElement = document.querySelector(".menu") as HTMLDivElement;
        const megaMenu: HTMLDivElement = document.querySelector(".mega-menu") as HTMLDivElement;
        const menuItems: NodeListOf<HTMLDivElement> = document.querySelectorAll(".mega-menu .menu-item") as NodeListOf<HTMLDivElement>;

        if (window.screen.width < 768) {
            burgerMenu.setAttribute("data-bs-toggle", "modal");
            burgerMenu.setAttribute("data-bs-target", "#mainNavModal");
            burgerMenu.setAttribute("type", "button");
        }
        else {
            burgerMenu.removeAttribute("data-bs-toggle");
            burgerMenu.removeAttribute("data-bs-target");
            burgerMenu.removeAttribute("type");

            if (burgerMenu) {
                burgerMenu.addEventListener("mouseover", (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    burgerMenu.querySelector(".mega-menu").classList.add("burger-menu-hover");
                    navMenu.classList.add("menu-hover");
                });
            }

            if (document) {
                document.addEventListener("mouseover", (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const target: HTMLDivElement = e.target as HTMLDivElement;
                    if (target != nav && target != navMenu && target != megaMenu && target != burgerMenu) {
                        megaMenu.classList.remove("burger-menu-hover")
                        navMenu.classList.remove("menu-hover");
                        const currentHover: HTMLDivElement = document.querySelector(".permahover");
                        if (currentHover) {
                            currentHover.classList.remove("permahover")
                        }
                    }
                });
            }

            if (menuItems) {

                menuItems.forEach((menuItem) => {
                    menuItem.addEventListener("mouseover", (e) => {
                        e.preventDefault();

                        const currentHover: HTMLDivElement = document.querySelector(".permahover");
                        if (currentHover) {
                            currentHover.classList.remove("permahover")
                        }

                        menuItem.querySelector(".sub-menu-item-container").classList.add("permahover");

                    });
                })

            }

        }
    }
}