import BaseForm, { BaseFields, BaseEnquiryData } from './base-form';

export interface EnquiryFields extends BaseFields {
    Name: HTMLInputElement;
    PreferPhone: HTMLInputElement;
    PreferEmail: HTMLInputElement;
    Phone: HTMLInputElement;
    Email: HTMLInputElement;
    Message: HTMLTextAreaElement;
    "g-recaptcha-response": HTMLInputElement;
}

export interface EnquiryData extends BaseEnquiryData {
    Name: string;
    PreferPhone: boolean;
    PreferEmail: boolean;
    Phone: string;
    Email: string;
    Message: string;
    "g-recaptcha-response": string;
}

export default class EnquiryForm extends BaseForm {
    Fields: EnquiryFields;

    /**
     * Constructor is called explicitly in view, so components can be guaranteed to exist
     * */
    constructor() {

        super();

        this.Fields = {
            Name: document.getElementById('enquiry-name') as HTMLInputElement,
            PreferPhone: document.getElementById('enquiry-prefer-phone') as HTMLInputElement,
            PreferEmail: document.getElementById('enquiry-prefer-email') as HTMLInputElement,
            Phone: document.getElementById('enquiry-phone') as HTMLInputElement,
            Email: document.getElementById('enquiry-email') as HTMLInputElement,
            Message: document.getElementById('enquiry-message') as HTMLTextAreaElement,
            "g-recaptcha-response": document.getElementById('captcha-response') as HTMLInputElement,
            Submit: this.BaseFields.Submit
        };
    }

    /**
     * @summary Reads form data and sends form data to API
     */
    HandleFormSubmission() {
        const data: EnquiryData = {
            Name: this.Fields.Name.value,
            PreferPhone: this.Fields.PreferPhone.checked,
            PreferEmail: this.Fields.PreferEmail.checked,
            Phone: this.Fields.Phone.value,
            Email: this.Fields.Email.value,
            Message: this.Fields.Message.value,
            "g-recaptcha-response": this.Fields['g-recaptcha-response'].value
        };

        if (this.IsFormValid(data)) {

            this.SendRequest(data);
        }
    }

    /**
     * Validates form data
     * @param data Form data to validate
     * @returns True or false
     */
    IsFormValid(data: EnquiryData): boolean {

        let isValid: boolean = true;
        let grandparent: HTMLDivElement = this.Fields.Name.parentElement.parentElement as HTMLDivElement;
        let errorMessage: HTMLSpanElement = grandparent.querySelector('.error-message') as HTMLSpanElement;

        // Validate first name has been provided
        if (!data.Name) {
            grandparent.classList.add('error');
            errorMessage.innerText = "Please provide a name";
            isValid = false;
        }
        else {
            grandparent.classList.remove('error');
        }

        grandparent = this.Fields.Phone.parentElement.parentElement as HTMLDivElement;
        errorMessage = grandparent.querySelector('.error-message') as HTMLSpanElement;

        // Only run validations if phone is preferred contact
        if (data.PreferPhone) {
            // Validate phone has been provided
            if (!data.Phone) {
                grandparent.classList.add('error');
                errorMessage.innerText = "Please provide a phone number";
                isValid = false;
            }
            else {
                grandparent.classList.remove('error');
            }
        }
        else {
            grandparent.classList.remove('error');
        }

        grandparent = this.Fields.Email.parentElement.parentElement as HTMLDivElement;
        errorMessage = grandparent.querySelector('.error-message') as HTMLSpanElement;

        // Only run validations if email is preferred contact
        if (data.PreferEmail) {
            // Validate email has been provided
            if (!data.Email) {
                grandparent.classList.add('error');
                errorMessage.innerText = "Please provide an email";
                isValid = false;
            }
            // Validate email is vaguely correct format
            else if (!data.Email.match(/\S+@\S+\.\S+/)) {
                grandparent.classList.add('error');
                errorMessage.innerText = "Please provide a valid email";
                isValid = false;
            }
            else {
                grandparent.classList.remove('error');
            }
        }
        else {
            grandparent.classList.remove('error');
        }

        grandparent = this.Fields.Message.parentElement.parentElement as HTMLDivElement;
        errorMessage = grandparent.querySelector('.error-message') as HTMLSpanElement;

        // Validate message has been provided
        if (!data.Message) {
            grandparent.classList.add('error');
            errorMessage.innerText = "Please provide a message";
            isValid = false;
        }
        else {
            grandparent.classList.remove('error');
        }

        return isValid;
    }
}