import Splide from '@splidejs/splide';

export default class Carousel {
    Carousel: Splide;

    constructor(id: string) {

        this.Carousel = new Splide(`#${id}`, {
            classes: {
                arrows: 'splide__arrows carousel-arrows',
                arrow: 'splide__arrow carousel-arrow',
                prev: 'splide__arrow--prev carousel-prev',
                next: 'splide__arrow--next carousel-next',
                pagination: 'splide__pagination carousel-pagination',
                page: 'splide__pagination__page carousel-page'
            }
        }).mount();
    }
}