import { RouteAPI } from '../helpers/url-helpers';

export interface BaseFields {
    Submit: HTMLDivElement;
}

export interface BaseEnquiryData {

}

export default abstract class BaseForm {
    BaseFields: BaseFields;
    Route: string;

    // Constructor is called explicitly in view, so components can be guaranteed to exist
    constructor() {

        this.BaseFields = {
            Submit: document.getElementById('enquiry-submit') as HTMLDivElement
        };

        // Connect event handler to this object - must be bound before event handlers are registered
        this.HandleFormSubmission = this.HandleFormSubmission.bind(this);


        this.BaseFields.Submit.addEventListener('click', (e) => {
            e.preventDefault();
            this.HandleFormSubmission();
        });

        const success: HTMLSpanElement = document.getElementById('enquiry-success') as HTMLSpanElement;
        
        const urlParams = new URLSearchParams(window.location.search);
        const message = urlParams.get('message');
        if (message != "") {
            success.style.display = 'inline-block';
        }

    }

    /**
     * @summary Called when submission button clicked
     */
    abstract HandleFormSubmission(): void;

    /**
     * Sends form data to API
     * @param data
     */
    SendRequest(data: BaseEnquiryData) {
        const captchaRes: HTMLInputElement = document.getElementById('captcha-response') as HTMLInputElement
        const url = RouteAPI(this.Route) + "?g-recaptcha-response=" + captchaRes.value;
        
        const request = new XMLHttpRequest();
        request.open('POST', url);
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.send(JSON.stringify(data));

        request.onload = () => {
            const success: HTMLSpanElement = document.getElementById('enquiry-success') as HTMLSpanElement;
            success.style.display = 'inline-block';
            if (request.status === 200) { 
                window.location.href = "/contact?message=Message%20has%20been%20received!"
                success.innerText = 'Message has been received!';
                success.classList.remove('error');
            }
            else {

                success.innerText = 'Something has gone wrong';
                success.classList.add('error');
                console.error(`Enquiry failed with code ${request.status}`);
            }
        }
    }

    /**
     * Validates form data
     * @param data Form data to validate
     * @returns True or false
     */
    abstract IsFormValid(data: BaseEnquiryData): boolean;
}